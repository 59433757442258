import { Injectable } from "@angular/core"
import { tap } from "rxjs/operators"
import { Team } from "src/models/team/team"
import { ConnectionService } from "src/services/connection.service"
import { ResourceService } from "src/services/resource.service"
import { TeamService } from "./team.service"

@Injectable({
  providedIn: 'root'
})
export class TeamAdminService extends ResourceService<Team> {


  constructor(private _connection: ConnectionService, private teamService: TeamService) {
    super(_connection, "team-admin/", Team)
  }

  getTeamsWithPendingVacancies() {
    return this.getConnection().get(this.endpoint + "get-teams-with-violet-600-vacancies", { handleLoading: true, loadingMsg: 'Loading Requested Teams' })
      .pipe(tap((teams: Team[]) => teams.forEach(team => team.groups.map(this.teamService.mergeUnMatched.bind(this.teamService)))))
  }

  getCompletedTeams() {
    return this.getConnection().get(this.endpoint + "get-completed-teams")
      .pipe(tap((teams: Team[]) => teams.forEach(team => team.groups.map(this.teamService.mergeUnMatched.bind(this.teamService)))))
  }

  getTeamsWithActiveVacancies() {
    return this.getConnection().get(this.endpoint + "get-teams-with-active-vacancies")
      .pipe(tap((teams: Team[]) => teams.forEach(team => team.groups.map(this.teamService.mergeUnMatched.bind(this.teamService)))))
  }

  getTeamWithActiveVacancies(teamId) {
    return this.getConnection().get(this.endpoint + "get-team-with-active-vacancies?teamId=" + teamId, { handleLoading: true, loadingMsg: 'loading team info' })
      .pipe(tap((team: Team) => team.groups.map(this.teamService.mergeUnMatched.bind(this.teamService))))
  }
}  