import { HeaderLink, blogLink, helpCenterLink } from './../../header-data';

export const jobHeaderNavLinks: HeaderLink[] = [
  {
    name: "Hire",
    url: "/"
  },
  {
    name: "Browse Jobs",
    url: "/skilled-people/find-work"
  },
  {
    name: "Join Community",
    url: "/skilled-people",
    exactHighlight:true 
  },
  {
    name: "Find Salaries",
    url: "/find-salaries"
  },
  helpCenterLink,
]

export const skilledLandingHeaderNavLinks: HeaderLink[] = [
  {
    name: "Browse Jobs",
    url: "/skilled-people"
  },
  {
    name: "Find Salaries",
    url: "/find-salaries"
  },
  helpCenterLink,
  blogLink
]

export const defaultHeaderNavLinks: HeaderLink[] = [
  {
    name: "Hire",
    url: "/"
  },
  {
    name: "Find Work",
    url: "/skilled-people"
  },
  {
    name: "Find Salaries",
    url: "/find-salaries"
  },
  helpCenterLink
]
