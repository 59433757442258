import { Injectable } from '@angular/core';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Schema } from 'normalizr';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EmailInvitedToTeam } from 'src/models/emailInvited/emailInvitedToTeam';
import { Team, TeamNormalized, teamSchema } from 'src/models/team/team';
import { TeamAdminService } from 'src/models/team/team-admin.service';
import { TeamService } from 'src/models/team/team.service';
import { CollectionStoreService } from 'src/ngrx/store/collection-store.service';
import { GroupCollectionService } from '../group/group-collection.service';


@Injectable({ providedIn: 'root' })
export class TeamCollectionService extends CollectionStoreService<TeamNormalized> {

  schema: Schema = teamSchema;

  collectionServiceMap = {
    groups: this.groupCollectionService,
  }

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private groupCollectionService: GroupCollectionService, private teamService: TeamService,  private teamAdminService:TeamAdminService) {
    super('Team', serviceElementsFactory);
  }

  getTeamsWithPendingVacancy(): Observable<TeamNormalized[]> {
    return this.teamAdminService.getTeamsWithPendingVacancies().pipe(
      map((teams: Team[]) => {
        return super.normalizeEntitiesAndAddToCache(teams)
      }))
  }

  getCompletedTeams(): Observable<TeamNormalized[]> {
    return this.teamAdminService.getCompletedTeams().pipe(
      map((teams: Team[]) => {
        return super.normalizeEntitiesAndAddToCache(teams)
      })
    )
  }

  getTeamsWithActiveVacancy(): Observable<TeamNormalized[]> {
    return this.teamAdminService.getTeamsWithActiveVacancies().pipe(
      map((teams: Team[]) => {
        return super.normalizeEntitiesAndAddToCache(teams)
      })
    )
  }

  getTeamWithActiveVacancy(teamId): Observable<TeamNormalized[]> {
    return this.teamAdminService.getTeamWithActiveVacancies(teamId).pipe(
      map((team: Team) => {
        return super.normalizeEntitiesAndAddToCache([team])
      })
    )
  }

  renameTeamAPI(teamId: number, name: string) {
    this.updateOneInCache({
      id: teamId,
      name
    })
    return this.teamService.renameTeam(teamId, name)
  }

  deleteTeamAPI(team: TeamNormalized) {
    return this.teamService.deleteTeam(team.id).pipe(tap(_=> {
        this.removeOneFromCache(team)
      
    }))
  }


  bookSavedTeamAPI(team: TeamNormalized) {
    return this.teamService.bookSavedTeam(team.id).pipe(tap(bookedTeam => {
      if (bookedTeam) {
        return super.normalizeEntitiesAndAddToCache([bookedTeam])
      }
    }))
  }

  addUsersToTeamAPI(team:TeamNormalized, usersEmails:string[] , message:string):Observable<Team>{
    this.updateOneInCache({
      id:team.id ,
      invitedEmails: team.invitedEmails.concat(usersEmails.map(email=>new EmailInvitedToTeam(email)))
    })
    return this.teamService.addUsersToTeam(team.id, usersEmails , message).pipe(tap(updatedTeam=>{
      this.updateOneInCache({
        id:team.id ,
        invitedEmails: updatedTeam.invitedEmails, 
        users: updatedTeam.users ,
        usersPermissionsAsList: updatedTeam.usersPermissionsAsList 
      })
    }))
  }

  removeUserFromTeamAPI(team:TeamNormalized, userEmail:string):Observable<void>{
    this.updateOneInCache({
      id:team.id ,
      invitedEmails: [...this.entitiesSnapshot.filter(entity=>entity.id==team.id)[0].invitedEmails.filter(emailEntity=>emailEntity.email!==userEmail)], 
      users:[...this.entitiesSnapshot.filter(entity=>entity.id==team.id)[0].users.filter(user=>user.email!==userEmail)]
    })
    return this.teamService.removeUserFromTeam(team.id,userEmail);
  }


}
