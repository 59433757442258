import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { HeaderServices } from '../header.service';

@Component({
  selector: 'app-header-side-nav',
  templateUrl: './header-side-nav.component.html',
  styleUrls: ['./header-side-nav.component.scss'],
  host: { ngSkipHydration: 'true' },
})
export class HeaderSideNavComponent implements OnInit {

  @Input() sidenav: MatSidenav

  constructor(
    public headerServices: HeaderServices,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
  }

  routeToLink(link: string) {
    this.router.navigateByUrl(link);
    return false;
  }

  signOut() {
    this.headerServices.signOut()
  }
}
