import { Injectable } from '@angular/core';
import { ConnectionService } from 'src/services/connection.service';
import { ResourceService } from 'src/services/resource.service';
import { AppUserNotification } from './appUserNotification';


@Injectable()
export class AppUserNotificationServices extends ResourceService<AppUserNotification> {

  constructor(connection:ConnectionService) {
    super(connection,'appuser-notification',AppUserNotification);
  }
  getUserFeed(){
    return this.connection.get(this.getEndPoint()+'/get-user-notifications');
  }

  deleteFeedEntry(feedId:Number)  {
    return this.connection.get(this.getEndPoint()+"/fake-delete?feedId="+feedId) ;
  }

  markFeedAsRead(){
    return this.connection.get(this.getEndPoint()+"/mark-as-read")
  }
}
