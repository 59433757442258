import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, map } from 'rxjs';
import { HelpChatBotService } from 'src/services/help-chat-bot.service';
import { SfBrowserUtilities } from 'src/services/sf-browser-utilities.service';
import { HeaderServices } from '../../header.service';
import { CompanyService } from './../../../../../models/company/company.service';

@Component({
  selector: 'app-header-customer-side-nav',
  templateUrl: './header-customer-side-nav.component.html',
  styleUrls: ['./header-customer-side-nav.component.scss'],
})
export class HeaderCustomerSideNavComponent implements OnInit {

  @Input() sidenav: MatSidenav;

  companyId$: Observable<number>

  constructor(public headerServices: HeaderServices,
    private companyService: CompanyService,
    private helpChatBotService: HelpChatBotService,
    private sfBrowserUtilities:SfBrowserUtilities,
  ) { }

  ngOnInit(): void {
    this.companyId$ = this.companyService.currentUserCompanyCached().pipe(map(company => company?.id))
  }

  ngAfterViewInit(): void {
  }

  handleClick(){
    if(!this.headerServices.isSideNavSticky)
      this.sidenav.close()
  }

  openContactUs() {
    this.sidenav.close()
    this.helpChatBotService.openChat()
  }
}
