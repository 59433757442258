import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { ExceptionName } from 'src/models/apiError';
import { ApiSuccess } from 'src/models/apiSuccess';
import { CometChatService } from 'src/services/third-party/comet-chat.service';
import { UserLocalStorageService, UserTokens } from 'src/services/user-local-storage.service';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    private refreshTokenInProgress = false;
    constructor(private authService: AuthenticationService,   private cometChatService: CometChatService
        ,private userLocalStorageService: UserLocalStorageService) { }

    addTokenToHeader(request: HttpRequest<any>, token?) {
        if (this.userLocalStorageService.getItem('jwt'))
            token = this.userLocalStorageService.getItem('jwt');
        if (token && request.withCredentials) {
            request = request.clone({
                setHeaders: {
                    "Authorization": `Bearer ${token}`,
                }

            });
        }
        return request;

    }

    refreshTokenAndReq(request: HttpRequest<any>, next: HttpHandler) {
        this.refreshTokenInProgress = true
        return new Observable<HttpEvent<any>>(observer => {
            this.authService.refreshToken().subscribe((res: ApiSuccess) => {

                let token: String = res.data.token.substring(7)
                this.refreshTokenInProgress = false;
            
                request = this.addTokenToHeader(request, token)
                if(res.data.needsLogin  === true){
                    this.cometChatService.loginCometChat().pipe(first()).subscribe();
                }

                // Do the request again with the new token.
                next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
                    observer.error(error);
                    return throwError(error);
                })).subscribe(res => { observer.next(res) })

            }, err => {
                // refresh token expired (request will fail)
                this.refreshTokenInProgress = false;
                this.authService.signOut()
            });
        }).pipe(catchError((error: HttpErrorResponse) => {
            return throwError(error)
        }));
    }

    isAccessTokenExpired() {
        let accessToken = this.userLocalStorageService.getItem("jwt")
        if (!accessToken) // if no access token then no expiration
            return false
        let auth: UserTokens = this.userLocalStorageService.getItem("Auth")
        let expTime = auth.access_exp_at
        let now = new Date().getTime()
        if (now > expTime)
            return true;
        else
            return false;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isAccessTokenExpired() && !this.refreshTokenInProgress) {
            return this.refreshTokenAndReq(request, next);
        }

        // don't add access token in the header of a refresh token request
        // to avoid backend auth filter
        if (request.url.includes("user/refresh-token"))
            return next.handle(request)
        else
            request = this.addTokenToHeader(request);


        return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
            // if token expired and refresh token is not sent yet

            if (error.status === 401 && error.error.exceptionName == ExceptionName.TOKEN_EXPIERED ) {
                return this.refreshTokenAndReq(request, next);
            }
            else {
                return throwError(error);
            }
        }));


    }
}
