<app-package-upgrade-btn *ngIf="showUpgradeBtn" (click)="goPremiumClicked.emit()"> </app-package-upgrade-btn>


<span [matTooltip]="tooltipMsg" class="cursor-pointer" (click)="openDetailsOverlay()">
  <app-company-remaining-credits> </app-company-remaining-credits>
</span>



<ng-template #remainingCreditsDetails>
  <div class="remaining-credits-details">
    <app-company-remaining-credits #overlayRemainingCredits> </app-company-remaining-credits>
    <div class="content">
      <div class="fw-500 mg-b-4"> {{overlayRemainingCredits.remainingCredits.totalCredits| number}} REMAINING CREDITS
      </div>
      <div class="f-14"> Used to create assessments with AI
        or source from more than 2.1 billion profiles. Check credits menu
        <a href=" https://help.supportfinity.com/en/articles/9451129-credits-menu-and-usage" target="_blank"> here </a>
      </div>
    </div>
    <button mat-button class="btn-bk-blue-gradient" (click)="openRefill()"> Refill now </button>
  </div>
</ng-template>