import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, UrlSegment, ActivatedRouteSnapshot, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {

    constructor(
        private authenticationService: AuthenticationService,
        private dialog: MatDialog,
        private router: Router,
        private location:Location) {}

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean>|Promise<boolean>|boolean {

        if (this.authenticationService.isLoggedIn() && this.authenticationService.isUserAdmin()) {
            // logged in so return true
            return true;
        }
        this.router.navigateByUrl('')
        return false
    }

}
