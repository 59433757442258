import { ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { Component, ElementRef, EventEmitter, Output, TemplateRef, ViewChild, ViewContainerRef, type OnInit } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NavigationEnd, Router, RouterModule } from "@angular/router";
import { Subject, filter, takeUntil } from "rxjs";
import { CompanyRemainingCreditsComponent } from "src/app/customer/company/company-remaining-credits/company-remaining-credits.component";
import { UpgradeNeededHandlerService } from "src/app/customer/customer-dashboard/job-list-dashboard/job-board-packages-stepper/upgrade-needed-handler.service";
import { PackageUpgradeBtnComponent } from "src/app/shared/buttons/package-upgrade-btn/package-upgrade-btn.component";
import { SvgIconModule } from "src/app/shared/icons/svg-icon/svg-icon.module";
import { CompanyJBPackageService } from "src/models/companyJBPackage/company-jb-package.service";
import { CompanyJBPackage } from "src/models/companyJBPackage/companyJBPackage";

@Component({
	selector: 'app-header-company-package-details',
	standalone: true,
	imports: [
		CommonModule,
		CompanyRemainingCreditsComponent,
		MatTooltipModule,
		MatButtonModule,
		SvgIconModule,
		RouterModule, 
		PackageUpgradeBtnComponent
	],
	templateUrl: './header-company-package-details.component.html',
	styleUrls: ['./header-company-package-details.component.scss'],
})
export class HeaderCompanyPackageDetailsComponent implements OnInit {
	@ViewChild('remainingCreditsDetails') remainingCreditsDetailsTemp: TemplateRef<any>

	@Output() goPremiumClicked: EventEmitter<void> = new EventEmitter()

	remainingCreditsDetailsOverlay: OverlayRef;

	terminateSubs$: Subject<void> = new Subject();
	isOnFreePlan: boolean = false
	tooltipMsg: string;
	isOnPricingPage: boolean = false;
	showUpgradeBtn: boolean = false;

	constructor(private overlay: Overlay,
		private elementRef: ElementRef,
		private companyJBPackageService: CompanyJBPackageService,
		private upgradeNeededHandler: UpgradeNeededHandlerService,
		private router: Router,
		private _viewContainerRef: ViewContainerRef) {

	}
	ngOnInit(): void {

		this.setCompanyPackageListener()
		this.setRouterListener()
	}

	setCompanyPackageListener() {
		this.companyJBPackageService.getCurrentUserCompanyJBPackage()
			.pipe(takeUntil(this.terminateSubs$),
				filter(currentPackage => currentPackage != null))
			.subscribe((companyJBPackage: CompanyJBPackage) => {
				this.isOnFreePlan = companyJBPackage.packageDetails.free
				this.setUpgradeBtnVisibility();
				this.tooltipMsg = `Your company has ${companyJBPackage.remainingCredits.totalCredits.toLocaleString()} remaining credits. Click to view details`
			})
	}


	setRouterListener() {
		this.router.events
			.pipe((filter(event => event instanceof NavigationEnd)),
				takeUntil(this.terminateSubs$))
			.subscribe((event: NavigationEnd) => {
				const currentUrl = event.urlAfterRedirects
				this.isOnPricingPage = currentUrl.includes('pricing')
				this.setUpgradeBtnVisibility();
			})
	}


	setUpgradeBtnVisibility() {
		this.showUpgradeBtn = this.isOnFreePlan && !this.isOnPricingPage
	}


	openDetailsOverlay() {

		let positionStrategy = this.overlay.position().flexibleConnectedTo(this.elementRef).withPositions(
			[
				new ConnectionPositionPair({ originX: 'center', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }, 0, 10),
			]
		);

		const config = new OverlayConfig({
			hasBackdrop: true,
			positionStrategy: positionStrategy,
			scrollStrategy: this.overlay.scrollStrategies.block(),
		});

		const overlayRef: OverlayRef = this.overlay.create(config);
		this.remainingCreditsDetailsOverlay = overlayRef
		overlayRef.backdropClick().pipe(takeUntil(this.terminateSubs$)).subscribe(_ => {
			this.closeOverlay()
		})

		let portal = new TemplatePortal(this.remainingCreditsDetailsTemp, this._viewContainerRef)
		overlayRef.attach(portal)
	}

	openRefill() {
		this.closeOverlay()
		this.upgradeNeededHandler.openAddCreditsPopup()
	}

	closeOverlay() {
		if (this.remainingCreditsDetailsOverlay)
			this.remainingCreditsDetailsOverlay.dispose()
	}

	ngOnDestroy(): void {
		this.terminateSubs$.next()
		this.terminateSubs$.complete()
	}

}
