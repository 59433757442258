import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthorizeDirectivesModule } from 'src/app/shared/directives/authorize/authorize-directives.module';
import { SvgIconModule } from 'src/app/shared/icons/svg-icon/svg-icon.module';
import { ImageRoundModule } from 'src/app/shared/image/image-round/image-round.module';
import { ImageSquareModule } from 'src/app/shared/image/image-square/image-square.module';
import { HeaderProfileContainerComponent } from './header-profile-container.component';



@NgModule({
  declarations: [
    HeaderProfileContainerComponent
  ],
  imports: [
    CommonModule,
    AuthorizeDirectivesModule,
    ImageRoundModule,
    ImageSquareModule,
    SvgIconModule
  ],
  exports:[
    HeaderProfileContainerComponent
  ]
})
export class HeaderProfileContainerModule { }
