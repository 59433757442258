import { from, Observable, throwError, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpXsrfTokenExtractor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { retryWhen, mergeMap, catchError, take, switchMap, first, filter } from 'rxjs/operators';
import { AuthenticationService } from 'src/services/authentication/authentication.service';

@Injectable()
export class XsrfInterceptor implements HttpInterceptor {

    headerName = "csrf-token"

    constructor(
      private tokenExtractor: HttpXsrfTokenExtractor,
      private authenticationService:AuthenticationService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler,retry=5): Observable<HttpEvent<any>> {
        let token = this.tokenExtractor.getToken() as string;
        if (token !== null)
          req = req.clone({ headers: req.headers.delete(this.headerName).append(this.headerName, token )})
        return next.handle(req).pipe(
            catchError(
              (error) => {
                if(retry == 0 || error.status != 403)
                  return throwError(error)
                return this.intercept(req,next,retry-1)
              })
        )
    }

}
