import { Injectable } from '@angular/core';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Schema } from 'normalizr';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Group, GroupNormalized, groupSchema } from 'src/models/group/group';
import { GroupService } from 'src/models/group/group.service';
import { TeamNormalized } from 'src/models/team/team';
import { CollectionStoreService } from 'src/ngrx/store/collection-store.service';
import { JobVacancyApplicationCollectionService } from '../job-vacancy-application/job-vacancy-application-collection.service';
import { JobVacancyCollectionService } from '../job-vacancy/job-vacancy-collection.service';
import { WorkingSkilledCollectionService } from '../working-skilled/working-skilled-collection.service';

@Injectable({ providedIn: 'root' })
export class GroupCollectionService extends CollectionStoreService<GroupNormalized> {

  schema: Schema<any>=groupSchema;

  collectionServiceMap={
    jobVacancyApplications:this.jobVacancyApplicationCollectionService,
    workingSkilled: this.workingSkilledCollectionService,
    jobVacancy:this.jobVacancyCollectionService
  }

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private jobVacancyApplicationCollectionService:JobVacancyApplicationCollectionService,
    private workingSkilledCollectionService:WorkingSkilledCollectionService,
    private jobVacancyCollectionService : JobVacancyCollectionService,
    private groupAPIService:GroupService) {
    super('Group', serviceElementsFactory);
  }


  addGroupToTeamAPI(group:Group , team:TeamNormalized):Observable<GroupNormalized>{
   return this.groupAPIService.addGroupToTeam(group, team).pipe(
      map((group:Group)=> <GroupNormalized>this.normalizeEntitiesAndAddToCache([group])[0])
    )
  }

  updateGroup(group:GroupNormalized){
    // call update
    this.updateOneInCache(group)
    return this.groupAPIService.updateGroup(this.denormalizedEntity(group));
  }

  renameGroup(groupId: number , name:string){
    // call update
    this.updateOneInCache({
      id :groupId ,
      name
    })
    return this.groupAPIService.renameGroup(groupId , name) ;
  }

  deleteGroup(group:GroupNormalized){
    return this.groupAPIService.deleteGroup(group.id).pipe(
      tap(_=>this.removeOneFromCache(group)))
  }


}
