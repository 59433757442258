import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { HeaderServices } from '../../header.service';

@Component({
  selector: 'app-header-anonymous-side-nav',
  templateUrl: './header-anonymous-side-nav.component.html',
  styleUrls: ['./header-anonymous-side-nav.component.scss']
})
export class HeaderAnonymousSideNavComponent implements OnInit {

  @Input() sidenav: MatSidenav

  constructor(
    public headerServices: HeaderServices
  ) { }

  ngOnInit(): void {
  }
}
