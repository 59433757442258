import { Injectable } from '@angular/core';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Schema } from 'normalizr';
import { WorkingSkilledNormalized, workingSkilledSchema } from 'src/models/workingSkilled/workingSkilled';
import { CollectionStoreService } from 'src/ngrx/store/collection-store.service';


@Injectable({ providedIn: 'root' })
export class WorkingSkilledCollectionService extends CollectionStoreService<WorkingSkilledNormalized> {

  schema: Schema = workingSkilledSchema;

  collectionServiceMap = {
  }

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('WorkingSkilled', serviceElementsFactory);
  }

  private filterByGroupIdSelector
    = (groupId: number) =>
      this.selectorService.createSelectorFiltered({ filterFn: ws => ws.groupId == groupId })

  getByGroupId =
    (groupId: number) =>
      this.selectorService.select({ selectors: [this.filterByGroupIdSelector(groupId)] })

  countByGroupId =
    (groupId: number) =>
      this.selectorService.selectCount({ selector: this.filterByGroupIdSelector(groupId) })


  private workingSkilledByGroupIdsSelector
    = (groupIds: number[]) =>
      this.selectorService.createSelectorFiltered({ filterFn: ws => groupIds.includes(ws.groupId) })

  countByGroupIds =
    (groupIds: number[]) =>
      this.selectorService.selectCount({ selector: this.workingSkilledByGroupIdsSelector(groupIds) })


}
