import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { HeaderServices } from '../../header.service';
import { NO_SCROLL_ROUTING_STATE } from './../../../../../services/routing-utilities.service';
import { FunctionalUtils } from './../../../../../utilities/functionalUtils';

@Component({
  selector: 'app-header-skilled-side-nav',
  templateUrl: './header-skilled-side-nav.component.html',
  styleUrls: ['./header-skilled-side-nav.component.scss']
})
export class HeaderSkilledSideNavComponent implements OnInit {

  @Input() sidenav: MatSidenav

  searchJobControl: UntypedFormControl

  constructor(
    public headerServices: HeaderServices,
    // private jobVacancyFilterService:JobVacancyFilterService,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    // this.searchJobControl = this.jobVacancyFilterService.searchQueryFC;
  }

  doSearch(searchQuery: string) {
    // this.jobVacancyFilterService.searchQueryChanged(searchQuery);
    this.router.navigate(['skilled-people/find-work'], { queryParams: { searchQuery: searchQuery } })
      .then(_ => this.sidenav.close())
  }

  openReferPopup() {
    this.sidenav.toggle()
    FunctionalUtils.cleanPath(this.location.path())
    this.router.navigateByUrl(FunctionalUtils.cleanPath(this.location.path()) + '/refer', { skipLocationChange: true, state: { ...NO_SCROLL_ROUTING_STATE } })
  }
}
