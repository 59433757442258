import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { HeaderServices } from '../../header.service';

@Component({
  selector: 'app-header-admin-side-nav',
  templateUrl: './header-admin-side-nav.component.html',
  styleUrls: ['./header-admin-side-nav.component.scss']
})
export class HeaderAdminSideNavComponent implements OnInit {

  @Input() sidenav: MatSidenav;

  constructor(
    public headerServices: HeaderServices
  ) { }

  ngOnInit(): void {
  }

  routeToLink(url) {
    return this.headerServices.routeToLink(url);
  }
}
