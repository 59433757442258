var deepFreeze = require('deep-freeze-strict');
export function storeFreeze(reducer) {
  return function freeze(state, action) {
    state = state || {};
    deepFreeze(state);
    // guard against trying to freeze null or undefined types
    if (action.payload) {
      deepFreeze(action.payload);
    }
    var nextState = reducer(state, action);
    deepFreeze(nextState);
    return nextState;
  };
}
