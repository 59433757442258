import { Location } from '@angular/common';
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GlobalConstants } from 'src/models/globalConstants';
import { AlertFactoryService } from '../services/alertFactory.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

    constructor(private alertFactory: AlertFactoryService, private location: Location) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let handleLoading = request.headers.get(GlobalConstants.HANDLE_LOADING_HEADER) == 'true'
        let loadingMsg = request.headers.get(GlobalConstants.LOADING_MSG_HEADER)
        request = request.clone({
            headers: request.headers.delete(GlobalConstants.HANDLE_LOADING_HEADER).delete(GlobalConstants.LOADING_MSG_HEADER)
        })
        if (handleLoading)
            this.alertFactory.openLoading(loadingMsg)

        return next.handle(request).pipe(
            tap((res: any) => {
                if (handleLoading && res.type == HttpEventType.Response)
                    this.alertFactory.closeLoading() // close loading

            }), catchError((error: any) => {

                //close loading in case of error
                if (handleLoading)
                    this.alertFactory.closeLoading() // close loading

                return throwError(error);
            }))
    }
}
