import { NgModule } from '@angular/core';
import { EntityDataModule } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { reducers } from '../reducers';
import { metaReducers } from '../reducers/index';
import { entityConfig } from './entity-metadata';

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({routerState:RouterState.Minimal}),
    EntityDataModule.forRoot(entityConfig),
    environment.production ? [] :StoreDevtoolsModule.instrument({name: 'SupportFinity Store', maxAge: 25, logOnly: environment.production }),
  ],
  providers: [],
})
export class AppStoreModule {
    constructor() {}
}
