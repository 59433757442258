export class Notification {
    id:Number; 
    title:String;
    message:String; 
    label:String;
    type:String; 
    url:String; 
}

export class NotificationType { 
    public static readonly  UPDATES = "UPDATES";  
    public static readonly  NEWS = "NEWS";  
    public static readonly SETTINGS = "SETTINGS";
    public static readonly BONUS = "BONUS"; 
    public static readonly JOBS ="JOBS"
}