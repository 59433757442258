import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelWithStatusComponent } from './label-with-status.component';



@NgModule({
  declarations: [LabelWithStatusComponent],
  imports: [
    CommonModule
  ],
  exports:[LabelWithStatusComponent]
})
export class LabelWithStatusModule { }
