import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertFactoryService } from 'src/services/alertFactory.service';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class HrGuard  {

  constructor(private authenticationService: AuthenticationService,
    private alertFactory: AlertFactoryService,
    private router: Router,
    private location: Location) { }

  canAccess() {
    if (!this.authenticationService.isLoggedIn() || this.authenticationService.isUserHr()
      || this.authenticationService.isUserAdmin()) {
      return true;
    }
    let subs = this.alertFactory.openWarningSnackBar({
      title: "Not Authorized",
      message: `This type of accounts has no access to this page`
    }).subscribe(res => {
      this.authenticationService.navigateToUserDashboard().then(x => location.reload());
      subs.unsubscribe()
    })
    return false
  }
  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.canAccess();
  }
  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.canAccess();
  }


}
