import { EntityDataModuleConfig, EntityMetadataMap } from '@ngrx/data';

export const entityMetadata: EntityMetadataMap = {
  JobVacancyApplication: {
  },
  JobVacancyApplicationVM: {
    filterFn:null
  },
  WorkingSkilled: {
  },
  WorkingSkilledVM:{
  },
  JobVacancySkilledProfileMatch:{
    selectId:JobVacancySkilledProfileMatchSelectId
  },
  JobVacancy:{},
  JobVacancyVM:{},
  JobVacancyList:{},
  JobListVM: {},

};

export function JobVacancySkilledProfileMatchSelectId<T extends { id: any }>(entity: T) {
  return entity == null ? undefined : JSON.stringify(entity.id);
}

// because the plural of "hero" is not "heros"
const pluralNames = { };

export const entityConfig:EntityDataModuleConfig = {
  entityMetadata,
  pluralNames,

};
