import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Team, TeamNormalized } from 'src/models/team/team';
import { TeamService } from 'src/models/team/team.service';
import { TeamCollectionService } from 'src/ngrx/entities/team/team-collection.service';
import { StoreDataService } from 'src/ngrx/store/store-data.service';
import { HttpInjectableStoreDataService } from '../../store/http-injectable-store.service';

@Injectable()
export class TeamDataService extends StoreDataService<Team,TeamNormalized> {

  constructor(httpInjectableStoreDataService:HttpInjectableStoreDataService,
    private teamService:TeamService,
    private teamCollectionService:TeamCollectionService) {
    super('Team',teamCollectionService, httpInjectableStoreDataService);
  }

  getAll(): Observable<Team[]> {
    return this.teamService.getAllUserTeams().pipe(
      map((teams:Team[])=>{
        return super.normalizeEntitiesAndAddToCache(teams)
    }))
  }

//   getAll(): Observable<Team[]> {
//     return super.getAll().pipe(map(heroes => heroes.map(hero => this.mapHero(hero))));
//   }

//   getById(id: string | number): Observable<Team> {
//     return super.getById(id).pipe(map(hero => this.mapHero(hero)));
//   }

//   getWithQuery(params: string | QueryParams): Observable<Team[]> {
//     return super.getWithQuery(params).pipe(map(heroes => heroes.map(hero => this.mapHero(hero))));
//   }

//   private mapHero(team: Team): Team {
//     return { ...team, dateLoaded: new Date() };
//   }
}
